
ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 10px;
}


/* footer social icons */

.social-network a.icoTwitter:hover {
  background-color: rgb(29, 161, 242);
}

.social-network a.icoLinkedin:hover {
  background-color: #007bb7;
}
.social-network a.icoGithub:hover {
  background-color: rgb(71, 71, 71);
}
.social-network a.icoBlog:hover {
  background-color: #fc4f08;
}

.social-network a.icoTwitter:hover i,
.social-network a.icoGithub:hover i,
.social-network a.icoBlog:hover i,
.social-network a.icoLinkedin:hover i {
  color: #fff;
}

.social-network a.socialIcon:hover,
.socialHoverClass {
  color: #44BCDD;
}

.social-circle li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 28px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li a:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.social-circle i {
  color: #595959;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.social-network a {
  background-color: #F9F9F9;
}
textarea:focus, input:focus{
  outline: none;
}

.hover-b--current:hover,
.hover-b--current:focus  {
	border-color: currentColor;
}

.scroll {
  opacity: 0.5;
  background-color: rgb(0, 0, 0);
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border: none;

}
.scroll:hover{
    opacity: 1;
}

code {
  color:rgb(29, 161, 242);
}